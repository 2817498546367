import { Avatar } from "primereact/avatar";
import { ProgressBar } from "primereact/progressbar";

export default function ChatbotMessages({
  chatbotInitialized,
  messages,
  chatbot,
}) {
  if (
    chatbot &&
    chatbot.is_initialized &&
    chatbot.status === "Created" &&
    !!messages.length
  ) {
    return (
      <div className="flex align-items-center w-screen flex-column">
        {messages.map((message) => (
          <div class="flex bg-bluegray-50 border-round m-2 p-2 gap-2 chatbot-message-card">
            <div class="flex m-1">
              <Avatar
                label={message.label}
                size="medium"
                style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
                shape="circle"
              />
            </div>
            <div class="flex-1">
              <div className="flex flex-column">
                <div className="font-bold mb-2">{message.from}</div>
                <div>
                  {message.status === "pending" && (
                    <ProgressBar
                      mode="indeterminate"
                      style={{ height: "6px" }}
                    ></ProgressBar>
                  )}
                  {message.status === "sent" && message.text}
                </div>
                {message.sources?.map((doc, index) => {
                  return (
                    <div className="my-1">
                      Source #{index + 1}:{" "}
                      <a href={doc.source}>
                        {doc.filename} (Page {doc.page})
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
