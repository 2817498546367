import { Avatar } from "primereact/avatar";
import { ProgressBar } from "primereact/progressbar";
import {
  formatProcoreRfiUrl,
  formatProcoreSubmittalUrl,
} from "../../util/helper";
import { getCompanyId } from "../../util/storage";

export default function ChatbotMessages({
  chatbotInitialized,
  messages,
  chatbot,
}) {
  const companyId = getCompanyId();
  if (
    chatbot &&
    chatbot.is_initialized &&
    chatbot.status === "Created" &&
    !!messages.length
  ) {
    return (
      <div className="flex align-items-center w-screen flex-column">
        {messages.map((message) => (
          <div class="flex bg-bluegray-50 border-round m-2 p-2 gap-2 chatbot-message-card">
            <div class="flex m-1">
              <Avatar
                label={message.label}
                size="medium"
                style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
                shape="circle"
              />
            </div>
            <div class="flex-1">
              <div className="flex flex-column">
                <div className="font-bold mb-2">{message.from}</div>
                <div>
                  {message.status === "pending" && (
                    <ProgressBar
                      mode="indeterminate"
                      style={{ height: "6px" }}
                    ></ProgressBar>
                  )}
                  {message.status === "sent" && message.text}
                </div>
                {message.sources?.map((doc, index) => {
                  if (message.type === "document") {
                    return (
                      <div className="my-1">
                        Source #{index + 1}:{" "}
                        <a href={doc.source}>
                          {doc.filename} (Page {doc.page})
                        </a>
                      </div>
                    );
                  } else if (message.type === "submittal") {
                    return (
                      <div className="my-1">
                        Submittal #{doc.specification_section}-{doc.number}-
                        {doc.revision}:{" "}
                        <a
                          target="_blank"
                          href={formatProcoreSubmittalUrl(
                            doc.project_id,
                            doc.id
                          )}
                        >
                          {doc.title}
                        </a>
                      </div>
                    );
                  } else if (message.type === "rfi") {
                    return (
                      <div className="my-1">
                        RFI #{doc.number}:{" "}
                        <a
                          target="_blank"
                          href={formatProcoreRfiUrl(
                            companyId,
                            doc.project_id,
                            doc.id
                          )}
                        >
                          {doc.subject}
                        </a>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
