import React from "react";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { PrimeReactProvider } from "primereact/api";
import { queryClient } from "./api/requestProcessor";
import { store } from "./store/configureStore";

import Root from "./pages/Root";
import Login from "./components/Login";
import TaskPage from "./pages/Task";
import RFIPage from "./pages/RFI";
import EntityPage from "./pages/Entity";
import PromptForm from "./components/Input/Prompt";
import HandlerForm from "./components/Input/Handler";
import { Navigate } from "react-router-dom";
import {
  checkAdminAuthLoader,
  checkAuthLoader,
  checkOnlyAuthLoader,
  tokenLoader,
} from "./util/auth";
import SubmittalPage from "./pages/Submittal";
import SettingsPage from "./pages/Settings";
import CompanySelectionPage from "./pages/CompanySelection";
import NewUser from "./components/Entities/NewUser";
import UserDetails from "./components/Entities/UserDetails";
import { AppProvider } from "./context/AppProvider";
import NewSubmittal from "./components/Submittals/NewSubmittal";
import EditSubmittal from "./components/Submittals/EditSubmittal";
import SubmittalDetails from "./components/Submittals/SubmittalDetails";
import DraftAiSubmittal from "./components/Submittals/DraftAiSubmittal";
import Dashboard from "./pages/Dashboard";
import { getUserData } from "./util/storage";
import FolderTable from "./components/Folders/FolderTable";
import ChatbotsPage from "./pages/Chatbots";
import NewRFIPage from "./pages/NewRFI";
import EditRFIPage from "./pages/EditRfi";
import ViewRFIPage from "./pages/ViewRfi";
import DailyReportsPage from "./pages/DailyReports";
import OtpLogin from "./pages/OtpLogin";
import AdminRoot from "./pages/AdminRoot";
import AdminCompanies from "./components/Admin/Companies/Companies";
import Onboarding from "./components/Admin/Onboarding/Onboarding";
import PunchList from "./pages/PunchList";
import PunchListItem from "./pages/PunchListItem";
import UserOnboard from "./pages/UserOnboard";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      loader: checkAuthLoader,
      children: [
        {
          index: true,
          element: getUserData()?.is_procore_user ? (
            <TaskPage />
          ) : (
            <Dashboard />
          ),
        },
        { path: "tasks", loader: checkAuthLoader, element: <TaskPage /> },
        { path: "rfis", loader: checkAuthLoader, element: <RFIPage /> },
        {
          path: "rfis/:rfiId/edit",
          loader: checkAuthLoader,
          element: <EditRFIPage />,
        },
        {
          path: "rfis/:rfiId/view",
          loader: checkAuthLoader,
          element: <ViewRFIPage />,
        },
        {
          path: "rfis/new",
          loader: checkAuthLoader,
          element: <NewRFIPage />,
        },
        {
          path: "submittals",
          loader: checkAuthLoader,
          element: <SubmittalPage />,
        },
        {
          path: "submittals/:submittalId/edit",
          loader: checkAuthLoader,
          element: <EditSubmittal />,
        },
        {
          path: "submittals/:submittalId/view",
          loader: checkAuthLoader,
          element: <SubmittalDetails />,
        },
        {
          path: "submittals/new",
          loader: checkAuthLoader,
          element: <NewSubmittal />,
        },
        {
          path: "submittals/draft",
          loader: checkAuthLoader,
          element: <DraftAiSubmittal />,
        },
        { path: "directory", loader: checkAuthLoader, element: <EntityPage /> },
        {
          path: "directory/:userId",
          loader: checkAuthLoader,
          element: <UserDetails />,
        },
        {
          path: "directory/new",
          loader: checkAuthLoader,
          element: <NewUser />,
        },
        { path: "prompt", loader: checkAuthLoader, element: <PromptForm /> },
        { path: "handler", loader: checkAuthLoader, element: <HandlerForm /> },
        {
          path: "folders",
          loader: checkAuthLoader,
          element: <FolderTable />,
        },
        {
          path: "punch-lists",
          loader: checkAuthLoader,
          element: <PunchList />,
        },
        {
          path: "punch-lists/:punchListId",
          loader: checkAuthLoader,
          element: <PunchListItem />,
        },
        {
          path: "daily-reports",
          loader: checkAuthLoader,
          element: <DailyReportsPage />,
        },
      ],
    },
    {
      path: "/admin",
      element: <AdminRoot />,
      loader: checkAdminAuthLoader,
      children: [
        {
          index: true,
          element: <AdminCompanies />,
        },
        {
          path: "companies",
          element: <AdminCompanies />,
        },
        {
          path: "onboarding",
          element: <Onboarding />,
        },
      ],
    },
    {
      path: "company-selection",
      loader: checkOnlyAuthLoader,
      element: <CompanySelectionPage />,
    },
    {
      path: "user-onboard",
      loader: checkOnlyAuthLoader,
      element: <UserOnboard />,
    },
    {
      path: "settings",
      loader: checkAuthLoader,
      element: <SettingsPage />,
    },
    {
      path: "chatbots",
      loader: checkAuthLoader,
      element: <ChatbotsPage />,
    },
    { path: "/login", loader: tokenLoader, element: <OtpLogin /> },
    { path: "/login-quey5674", loader: tokenLoader, element: <Login /> },
    { path: "*", element: <Navigate to="/" /> },
  ]);

  return (
    <Provider store={store}>
      <AppProvider>
        <QueryClientProvider client={queryClient}>
          <PrimeReactProvider>
            <RouterProvider router={router} />
          </PrimeReactProvider>
        </QueryClientProvider>
      </AppProvider>
    </Provider>
  );
}

export default App;
