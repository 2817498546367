import { Outlet } from "react-router-dom";

import Navbar from "../components/Navbar";
import MobileMenu from "../components/MobileMenu";

export default function Root() {
  return (
    <>
      <Navbar />
      <div className="sm:mx-5 mt-4 mb-7 md:mb-0 layout-main-container top-0">
        <Outlet />
      </div>
      <MobileMenu />
    </>
  );
}
