import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppProvider";

export default function CustomCard({
  editMode,
  title,
  body,
  assignees,
  number,
  status,
  date,
  item,
  editHandler,
  deleteHandler,
  createReply,
  openSubmittalAttachments,
  onStatusChanged,
}) {
  const [isClicked, setIsClicked] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AppContext);

  const getSeverity = (status) => {
    switch (status.toLowerCase()) {
      case "open":
        return "danger";

      case "closed":
        return "success";

      case "draft":
        return "info";

      default:
        return "info";
    }
  };

  const handleCardClick = () => {
    if (editMode) {
      setIsClicked(!isClicked);
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    if (editHandler) {
      editHandler(item);
    }
  };

  const handleCreateReply = (e) => {
    e.preventDefault();
    if (createReply) {
      createReply(item);
    }
  };

  const handleStatus = (e) => {
    e.preventDefault();
    if (onStatusChanged) {
      onStatusChanged(item);
    }
  };

  const handleOpenSubmittalAttachments = (e) => {
    e.preventDefault();
    if (openSubmittalAttachments) {
      openSubmittalAttachments(item);
    }
  };

  const navigateEditSubmittal = (e) => {
    e.preventDefault();
    navigate(`/submittals/${item.id}/edit`);
  };

  const navigateViewSubmittal = (e) => {
    e.preventDefault();
    navigate(`/submittals/${item.id}/view`);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    if (deleteHandler) {
      deleteHandler(item);
    }
  };

  return (
    <div
      className="min-w-full p-2 mb-2 shadow-3 border-round-md"
      onClick={handleCardClick}
    >
      {isClicked ? (
        <div
          className="flex align-items-center justify-content-between flex-wrap"
          style={{ minHeight: "5rem" }}
        >
          {location.pathname === "/submittals" && user.role > 1 && (
            <div className="flex justify-content-center ml-2">
              <Button
                icon="pi pi-pencil"
                onClick={(e) => navigateEditSubmittal(e)}
              />

              <Button
                label="Edit"
                icon="pi pi-file-edit"
                onClick={(e) => handleEdit(e)}
              />
            </div>
          )}
          {location.pathname === "/submittals" && (
            <div className="flex justify-content-center ml-2">
              <Button
                icon="pi pi-eye"
                onClick={(e) => navigateViewSubmittal(e)}
              />
            </div>
          )}
          {(location.pathname === "/tasks" || location.pathname === "/") &&
            user.role > 1 && (
              <div className="flex justify-content-center">
                <Button
                  icon={
                    item.status === "Closed" ? "pi pi-lock-open" : "pi pi-lock"
                  }
                  onClick={(e) => handleStatus(e)}
                />
              </div>
            )}
          {location.pathname === "/rfis" && (
            <div className="flex justify-content-center">
              <Button
                icon="pi pi-comments"
                onClick={(e) => handleCreateReply(e)}
              />
            </div>
          )}
          {((location.pathname !== "/directory" && user.role > 1) ||
            (location.pathname === "/directory" && user.role > item?.role)) && (
            <div className="flex justify-content-center">
              <Button
                icon="pi pi-trash"
                severity="danger"
                onClick={(e) => handleDelete(e)}
              />
            </div>
          )}
          <div className="flex justify-content-center">
            <Button
              icon="pi pi-times"
              outlined
              severity="danger"
              aria-label="Cancel"
              onClick={handleCardClick}
            />
          </div>
        </div>
      ) : (
        <div className="grid text-sm">
          <div className="col-8">
            <div className="text-base font-semibold">{title}</div>
            <div>{body}</div>
            {/* TODO: Assign a different name for entities, don't use assignees */}
            {location.pathname !== "/directory" ? (
              location.pathname === "/tasks" ? (
                <div>Assignee: {assignees}</div>
              ) : (
                <div>Assignees: {assignees}</div>
              )
            ) : (
              <div>{assignees}</div>
            )}
            <div>{number ? `# ${number}` : ""}</div>
          </div>
          <div className="col-4 flex flex-column align-items-end">
            {location.pathname !== "/directory" ? (
              <div>
                <Tag value={status} severity={getSeverity(status)} />
              </div>
            ) : (
              ""
            )}
            <div>{date}</div>
          </div>
        </div>
      )}
    </div>
  );
}
