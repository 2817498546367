import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { getCoverSheetSetting, setCoverSheetSetting } from "../api/settingsAPI";
import { queryClient } from "../api/requestProcessor";
import { AppContext } from "../context/AppProvider";
import { getErrorMessage } from "../util/helper";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { getCurrentUser } from "../api/authAPI";

function UserSettings() {
  const [form, setForm] = useState({ should_create_cover_sheet: false });
  const { toast, setUser } = useContext(AppContext);
  const { data: coverSheetSetting, isFetching: isFetchingCoverSheetSetting } =
    useQuery({
      queryKey: ["coverSheetSetting"],
      queryFn: getCoverSheetSetting,
      staleTime: 5 * 60 * 1000,
    });

  const {
    data: currentUser,
    isFetching: isFetchingCurrentUser,
    refetch,
  } = useQuery({
    queryKey: ["currentUser"],
    queryFn: getCurrentUser,
    staleTime: 5 * 60 * 1000,
    enabled: false,
  });

  const {
    mutate: setCoverSheetSettingMutation,
    isPending: isPendingCoverSheetSetting,
  } = useMutation({
    mutationFn: setCoverSheetSetting,
    onSuccess: async (response) => {
      queryClient.invalidateQueries({ queryKey: ["coverSheetSetting"] });
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: response.message,
        life: 3000,
      });
      const newCurrentUser = await refetch();
      setUser(newCurrentUser.data);
      localStorage.setItem("userData", JSON.stringify(newCurrentUser.data));
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  useEffect(() => {
    if (coverSheetSetting) {
      setForm(coverSheetSetting);
    }
  }, [coverSheetSetting]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSave = () => {
    setCoverSheetSettingMutation({ coverSheetSetting: form });
  };

  return (
    <div>
      <div className="text-3xl font-semibold mb-5">User Settings</div>
      <div className="flex flex-column gap-2 max-w-25rem">
        <div className="field-checkbox">
          <Checkbox
            id="should_create_cover_sheet"
            name="should_create_cover_sheet"
            checked={form.should_create_cover_sheet}
            onChange={handleChange}
          />
          <label htmlFor="is_active">
            Auto-generate submittal cover sheet and merge with submittal
            attachments
          </label>
        </div>
        <Button
          label={
            isPendingCoverSheetSetting
              ? "Savings"
              : isFetchingCoverSheetSetting
              ? "Fetching Info"
              : "Save Settings"
          }
          onClick={handleSave}
          loading={isPendingCoverSheetSetting || isFetchingCoverSheetSetting}
          disabled={isPendingCoverSheetSetting || isFetchingCoverSheetSetting}
        />
      </div>
    </div>
  );
}

export default UserSettings;
