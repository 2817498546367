import axios from "axios";
import { getAuthToken } from "../util/auth";
import { getCompanyId, getProjectId, getUserData } from "../util/storage";
import API_BASE_URL from "../util/apiConfig";
import { convertToDate, generateBaseUrl, mapIds } from "../util/helper";

const token = getAuthToken();

const settingsClient = axios.create({
  baseURL: `${API_BASE_URL}/settings`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

settingsClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function getCoverSheetSetting({ signal }) {
  const response = await settingsClient.get("/cover-sheet", { signal });
  return response.data;
}

export async function setCoverSheetSetting({ coverSheetSetting }) {
  const response = await settingsClient.post("/cover-sheet", coverSheetSetting);
  return response.data;
}
