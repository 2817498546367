export function generateBaseUrl(isProcoreUser, projectId, endpoint) {
  const prefix = isProcoreUser ? "/procore" : "/nicky";
  return `${prefix}/projects/${projectId}/${endpoint}`;
}

export function formatAssignees(assignees) {
  return assignees.map((obj) => ({
    ...obj,
    id: obj.id.toString(),
  }));
}

export function getErrorMessage(error) {
  if (error.detail.message) {
    return error.detail.message;
  } else if (error.detail[0]) {
    return error.detail[0].msg;
  } else {
    return "An error occured";
  }
}

export function convertToDate(date) {
  const now = new Date(date);
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function truncateFileName(fileName, maxLength) {
  if (fileName.length <= maxLength) {
    return fileName;
  } else {
    const firstPart = fileName.substring(0, 7);
    const lastPart = fileName.substring(fileName.length - 7);
    return firstPart + "..." + lastPart;
  }
}

export function mapIds(obj) {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      if ("id" in obj[key]) {
        obj[key + "_id"] = obj[key].id;
      } else if (Array.isArray(obj[key])) {
        if (key === "distribution_members") {
          obj["distribution_member_ids"] = obj[key].map((member) => member.id);
        }
        if (key === "assignees") {
          obj["assignee_ids"] = obj[key].map((member) => member.id);
        }
        if (key === "distribution_list") {
          obj["distribution_ids"] = obj[key].map((member) => member.id);
        }
        obj[key].forEach((item) => mapIds(item));
      }
    }
  }
}

export function createNewDate(date) {
  return new Date(
    new Date(date).getTime() +
      Math.abs(new Date(date).getTimezoneOffset() * 60000)
  );
}
