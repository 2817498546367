import axios from "axios";
import { getAuthToken } from "../util/auth";
import API_BASE_URL from "../util/apiConfig";

const token = getAuthToken();

const adminClient = axios.create({
  baseURL: `${API_BASE_URL}/admin`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

adminClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function fetchAllUsers(signal) {
  const response = await adminClient.get("/users", signal);
  return response.data;
}

export async function fetchAllCompanies(signal) {
  const response = await adminClient.get("/companies", signal);
  return response.data;
}

export async function fetchAllProjects(signal) {
  const response = await adminClient.get(`/projects`, signal);
  return response.data;
}

export async function authorizeUser(authorizationData) {
  const response = await adminClient.post(`/authorize-user`, authorizationData);
  return response.data;
}
