import axios from "axios";
import { getAuthToken } from "../util/auth";
import { getCompanyId, getProjectId, getUserData } from "../util/storage";
import API_BASE_URL from "../util/apiConfig";
import { convertToDate, generateBaseUrl, mapIds } from "../util/helper";

const token = getAuthToken();

const rfisClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: "Bearer " + token,
  },
});

rfisClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function fetchRfis({ queryParams, signal }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const baseUrl = generateBaseUrl(isProcoreUser, projectId, "rfis");
  const companyId = getCompanyId();

  const queryString = new URLSearchParams(queryParams).toString();

  const response = await rfisClient.get(
    `${baseUrl}?${queryString}`,
    {
      params: {
        company_id: companyId,
      },
    },
    signal
  );
  return response.data;
}

export async function getRfi({ signal, rfiId }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const baseUrl = generateBaseUrl(isProcoreUser, projectId, `rfis/${rfiId}`);
  const companyId = getCompanyId();

  const response = await rfisClient.get(
    baseUrl,
    {
      params: {
        company_id: companyId,
      },
    },
    signal
  );

  mapIds(response.data);
  return response.data;
}

export async function createRfi({ rfiData, attachments }) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();
  const baseUrl = generateBaseUrl(isProcoreUser, projectId, "rfis");
  const companyId = getCompanyId();

  const rfiCreateData = rfiData;

  if (isProcoreUser) {
    rfiCreateData.question = {
      body: rfiData.rfi_body,
      prostore_file_ids: attachments.map((obj) => obj.id),
    };
    rfiCreateData.due_date =
      rfiCreateData.due_date && convertToDate(rfiCreateData.due_date);
    rfiCreateData.received_from_login_information_id =
      rfiCreateData.received_from_id;
  } else {
    rfiCreateData.attachments = attachments;
    rfiCreateData.number = rfiCreateData.number.toString();
    rfiCreateData.due_date =
      rfiCreateData.due_date && convertToDate(rfiCreateData.due_date);
  }

  const response = await rfisClient.post(baseUrl, rfiCreateData, {
    params: {
      company_id: companyId,
    },
  });
  return response.data;
}

export async function updateRfi({ rfiId, rfiData, attachments }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const baseUrl = generateBaseUrl(isProcoreUser, projectId, `rfis/${rfiId}`);
  const companyId = getCompanyId();

  const rfiUpdateData = rfiData;

  if (isProcoreUser) {
    rfiUpdateData.question = {
      body: rfiData.rfi_body,
      prostore_file_ids: attachments.map((obj) => obj.id),
    };
    rfiUpdateData.due_date =
      rfiUpdateData.due_date && convertToDate(rfiUpdateData.due_date);
    rfiUpdateData.received_from_login_information_id =
      rfiUpdateData.received_from_id;
  } else {
    rfiUpdateData.attachments = attachments;
    rfiUpdateData.number = rfiUpdateData.number.toString();
    rfiUpdateData.due_date =
      rfiUpdateData.due_date && convertToDate(rfiUpdateData.due_date);
  }

  const response = await rfisClient.patch(baseUrl, rfiUpdateData, {
    params: {
      company_id: companyId,
    },
  });

  return response.data;
}

export async function deleteRfi({ rfiId }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const baseUrl = generateBaseUrl(isProcoreUser, projectId, `rfis/${rfiId}`);
  const companyId = getCompanyId();

  const response = await rfisClient.delete(baseUrl, {
    params: {
      company_id: companyId,
    },
  });

  return response.data;
}

export async function getNextRfiNumber({ signal }) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const baseUrl = generateBaseUrl(isProcoreUser, projectId, `rfis/next-number`);

  const response = await rfisClient.get(
    baseUrl,
    {
      params: {
        company_id: companyId,
      },
    },
    signal
  );
  return response.data.next_number;
}
